import { YES_NOT_CODE } from '@bvl-core/shared/settings/constants/general.constants';

export const TYPE_ROBOT_ALLOW = 'Allow';
export const TYPE_ROBOT_DISALLOW = 'Disallow';
export const TYPE_ROBOT_SITEMAP = 'Sitemap';
export const TYPES_VALUES_ROBOTS = [
  {
    value: TYPE_ROBOT_ALLOW,
    text: 'Allowed'
  },
  {
    value: TYPE_ROBOT_DISALLOW,
    text: 'Disallowed'
  },
  {
    value: TYPE_ROBOT_SITEMAP,
    text: 'Sitemap'
  }
];

export const YES_NO_LIST = [
  {
    code: YES_NOT_CODE.NO,
    description: 'No'
  },
  {
    code: YES_NOT_CODE.YES,
    description: 'Si'
  }
];

export const ADVANCED_ROBOTS_LIST = [
  {
    code: 'noimageindex',
    description: 'Ningún indice de imagen'
  },
  {
    code: 'noarchive',
    description: 'Aplicar atributo "No Archive"'
  },
  {
    code: 'nosnippet',
    description: 'Sin snippet'
  }
];

export const SEO_REDIRECT_STATES = [
  {
    code: 1,
    description: 'Activo'
  },
  {
    code: 0,
    description: 'Inactivo'
  }
];
