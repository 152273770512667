import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationService } from '@bvl-admin/services';
import { EDITOR_HEADER_BUTTONS as COMMON_COMPONENT_HEADER_BUTTONS } from '@bvl-admin/settings/constants/component.constant';
import { EDITOR_TYPES } from '@bvl-admin/settings/constants/editor.constant';
import { ACTION_TYPES } from '@bvl-admin/settings/constants/general.constant';
import { EDITOR_HEADER_BUTTONS as PUBLICATION_HEADER_BUTTONS } from '@bvl-admin/settings/constants/page.constant';
import { GeneralLang } from '@bvl-admin/settings/lang';
import { ITrayItemButton } from '@bvl-admin/statemanagement/models/page.interface';
import { LANGUAGE_TYPES_CODE } from '@bvl-core/shared/settings/constants/general.constants';

@Component({
  selector: 'admin-editor-buttons-header',
  templateUrl: './editor-buttons-header.component.html',
  styleUrls: ['./editor-buttons-header.component.scss']
})
export class EditorButtonsHeaderComponent implements OnInit {

  @Input() editorType: number;

  protected _pageState: number;
  @Input()
  get pageState(): number {
    return this._pageState;
  }
  set pageState(value: number) {
    this._pageState = value || 0;
    this._createActionButtons(this.editorType);
  }

  @Output() changeLanguage: EventEmitter<string>;
  @Output() buttonAction: EventEmitter<string>;

  GENERAL_LANG = GeneralLang;

  LANGUAGE_TYPES_CODE = LANGUAGE_TYPES_CODE;
  ACTION_TYPES = ACTION_TYPES;

  frmEditorHeader: FormGroup;
  mLanguage: AbstractControl;
  actionButtons: { primary: Array<ITrayItemButton>, secundary: Array<ITrayItemButton> };

  constructor(
    private _formBuilder: FormBuilder,
    private _configurationService: ConfigurationService
  ) {
    this.changeLanguage = new EventEmitter<string>();
    this.buttonAction = new EventEmitter<string>();
  }

  ngOnInit(): void {
    this._createForm();
    this._createActionButtons(this.editorType);
  }

  private _createForm(): void {
    this.frmEditorHeader = this._formBuilder.group({
      mLanguage: [this.LANGUAGE_TYPES_CODE.es]
    });
    this.mLanguage = this.frmEditorHeader.get('mLanguage');
  }

  private _createActionButtons(editorType: number): void {
    let actionButtons: { primary: Array<ITrayItemButton>, secundary: Array<ITrayItemButton> } = {
      primary: [],
      secundary: []
    };
    switch (editorType) {
      case EDITOR_TYPES.commonComponent.code:
        actionButtons = COMMON_COMPONENT_HEADER_BUTTONS;
        break;
      case EDITOR_TYPES.publication.code:
      case EDITOR_TYPES.seePublication.code:
        if (this.pageState) {
          const userPermissions = this._configurationService.getUserPermissions();
          actionButtons = Object.keys(PUBLICATION_HEADER_BUTTONS)
                            .reduce((previous, current) => {
                              previous[current] = PUBLICATION_HEADER_BUTTONS[current].filter(fv => {
                                return fv.permissions.find(sv => {
                                  return sv.statesCode.includes(this.pageState) &&
                                          sv.rolesCode.includes(userPermissions.roleCode) &&
                                          sv.editorTypes.includes(editorType);
                                });
                              });

                              return previous;
                            }, { primary: [], secundary: []});
        }
        break;
      default:
        break;
    }
    this.actionButtons = actionButtons;
  }

  onChangeLanguage(): void {
    this.changeLanguage.next(this.mLanguage.value);
  }

  onButtonAction(action: string): void {
    this.buttonAction.next(action);
  }

}
