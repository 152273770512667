import { ACTION_TYPES } from './general.constant';

export const TRAY_COLLAPSE_BUTTONS = [
  {
    description: 'Eliminar',
    customClass: '',
    icon: 'la-trash',
    actionType: ACTION_TYPES.delete,
    isCollapsed: true
  },
  {
    description: 'Editar',
    customClass: '',
    icon: 'la-edit',
    actionType: ACTION_TYPES.edit,
    isCollapsed: true
  },
  {
    description: 'Guardar',
    customClass: '',
    icon: 'la-save',
    actionType: ACTION_TYPES.save,
    isCollapsed: false
  },
  {
    description: 'Cancelar',
    customClass: 'g-admin-btn-square-danger',
    icon: 'la-times-circle',
    actionType: ACTION_TYPES.cancel,
    isCollapsed: false
  }
];
