export const TAB_USERS_ROLES = [
  {
    name: 'Usuarios',
    route: './users-list'
  },
  {
    name: 'Roles',
    route: './roles-list'
  }
];

export const TAB_CONFIGURATION = [
  {
    name: 'General',
    route: './general'
  },
  {
    name: 'Redes Sociales',
    route: './social-networks'
  },
  {
    name: 'Robots',
    route: './robots'
  },
  {
    name: 'Sitemap',
    route: './sitemap'
  },
  {
    name: 'Avanzado',
    route: './advanced'
  },
  {
    name: 'Desarrollo',
    route: './developing'
  },
  {
    name: 'Redirección 301',
    route: './redirect'
  }
];
