import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BvlButtonModule, BvlCardModule, BvlCheckboxModule, BvlInputModule } from '@bvl-core/ui/lib/components';
import { CreateRoleFormComponent } from './create-role-form.component';

@NgModule({
  declarations: [CreateRoleFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BvlInputModule,
    BvlCardModule,
    BvlCheckboxModule,
    BvlButtonModule
  ],
  exports: [CreateRoleFormComponent],
  entryComponents: [CreateRoleFormComponent]
})
export class CreateRoleFormModule { }
