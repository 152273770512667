import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BvlButtonModule, BvlSwitchValueModule } from '@bvl-core/ui/lib/components';
import { EditorButtonsHeaderComponent } from './editor-buttons-header.component';

@NgModule({
  declarations: [EditorButtonsHeaderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BvlSwitchValueModule,
    BvlButtonModule
  ],
  exports: [
    EditorButtonsHeaderComponent
  ]
})
export class EditorButtonsHeaderModule { }
