import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermissionService, RoleService } from '@bvl-admin/services';
import { ACTION_TYPES } from '@bvl-admin/settings/constants/general.constant';
import { GeneralLang, RoleLang } from '@bvl-admin/settings/lang';
import { IPermissionResponse } from '@bvl-admin/statemanagement/models/permission.interface';
import { ISaveRoleRequest } from '@bvl-admin/statemanagement/models/role.interface';
import { ActiveModal } from '@bvl-core/shared/helpers/modal';
import { NotificationService } from '@bvl-core/shared/helpers/notification';
import { UnsubscribeOnDestroy, ValidatorUtil } from '@bvl-core/shared/helpers/util';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'admin-create-role-form',
  templateUrl: './create-role-form.component.html',
  styleUrls: ['./create-role-form.component.scss']
})
export class CreateRoleFormComponent extends UnsubscribeOnDestroy implements OnInit {

  GENERAL_LANG = GeneralLang;
  ROLE_LANG = RoleLang;

  frmCreateRole: FormGroup;
  role: AbstractControl;
  permissionsCheck: FormArray;
  permissionsList: Array<IPermissionResponse>;

  constructor(
    private _formBuilder: FormBuilder,
    private _permissionService: PermissionService,
    private _roleService: RoleService,
    private _notificationService: NotificationService,
    private _activeModal: ActiveModal
  ) {
    super();
  }

  ngOnInit(): void {
    this._createForm();
    this._permissionsList();
  }

  private _createForm(): void {
    this.frmCreateRole = this._formBuilder.group({
      role: [null, Validators.required],
      permissionsCheck: this._formBuilder.array([], ValidatorUtil.minChecksRequired())
    });
    this.role = this.frmCreateRole.get('role');
    this.permissionsCheck = this.frmCreateRole.get('permissionsCheck') as FormArray;
  }

  validateControl(controlName: string): boolean {
    return ValidatorUtil.validateControl(this.frmCreateRole, controlName);
  }

  private _permissionsList(): void {
    this._permissionService.permissionsList()
      .pipe(
        takeUntil(this.unsubscribeDestroy$)
      )
      .subscribe((response: Array<IPermissionResponse>) => {
        this.permissionsList = response;
        this._createPermissionsCheckControl();
      });
  }

  private _createPermissionsCheckControl(): void {
    this.permissionsList.forEach((fv, fk) => {
      this.permissionsCheck.setControl(fk, this._formBuilder.control(false));
    });
  }

  private _validateForm(): boolean {
    ValidatorUtil.validateForm(this.frmCreateRole);

    return this.frmCreateRole.valid;
  }

  private _paramsSaveRole(): ISaveRoleRequest {
    const getPermissionsCode = () => {
      const permissionsCode = [];
      this.permissionsCheck.controls.forEach((fv, fk) => {
        if (fv.value) { permissionsCode.push(this.permissionsList[fk].permissionCode); }
      });

      return permissionsCode;
    };

    return {
      roleName: this.role.value,
      permissionCodeList: getPermissionsCode()
    } as ISaveRoleRequest;
  }

  save(): void {
    const validateForm = this._validateForm();
    if (validateForm) {
      const params = this._paramsSaveRole();
      this._roleService.saveRole(params, true)
        .pipe(
          takeUntil(this.unsubscribeDestroy$)
        )
        .subscribe(() => {
          this._activeModal.close(ACTION_TYPES.save);
        });
    } else {
      this._notificationService.addWarning(this.GENERAL_LANG.notifications.completeFields);
    }
  }

}
