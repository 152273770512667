import { environment } from '@bvl-admin/environments/environment';

export class RobotsEndpoint {
  public static userAgent = `${environment.API_URL}/seo/robots/user-agent`;
  public static robots = `${environment.API_URL}/seo/robots`;
  public static addRobots = `${environment.API_URL}/seo/robots`;
  public static deleteRobots = `${environment.API_URL}/seo/robots/{id}`;
  public static generateRobots = `${environment.API_URL}/configurations/robots`;
  public static generateSitemap = `${environment.API_URL}/configurations/siteMap`;
}
