import { Injectable } from '@angular/core';
import { RobotsEndpoint } from '@bvl-admin/endpoints/robots.endpoint';
import {
  IObservableArrayRobots,
  IObservableArrayUserAgent,
  IRobotsRequest
} from '@bvl-admin/statemanagement/models/robots.interface';
import { ApiService } from '@bvl-core/shared/helpers/api';
import { IObservableVoid } from '@bvl-core/shared/helpers/models/general.model';

@Injectable({
  providedIn: 'root'
})
export class RobotsService {

  constructor(
    private apiService: ApiService
  ) { }

  getUserAgent(showSpin = true): IObservableArrayUserAgent {
    return this.apiService.get(RobotsEndpoint.userAgent, { preloader: showSpin });
  }

  getRobots(showSpin = true): IObservableArrayRobots {
    return this.apiService.get(RobotsEndpoint.robots, { preloader: showSpin });
  }

  addRobots(robots: IRobotsRequest, showSpin = true): IObservableVoid {
    return this.apiService.post(RobotsEndpoint.addRobots, robots, { preloader: showSpin });
  }

  deleteRobots(robotsId: string, showSpin = true): IObservableVoid {
    return this.apiService.del(RobotsEndpoint.deleteRobots, { preloader: showSpin, params: { id: robotsId } });
  }

  generateRobots(showSpin: boolean = true): IObservableVoid {
    return this.apiService.get(RobotsEndpoint.generateRobots, { preloader: showSpin });
  }

  generateSitemap(showSpin: boolean = true): IObservableVoid {
    return this.apiService.get(RobotsEndpoint.generateSitemap, { preloader: showSpin });
  }

}
