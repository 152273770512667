import { ITrayItemButton } from '@bvl-admin/statemanagement/models/page.interface';
import { ACTION_TYPES } from './general.constant';

export const COMPONENT_TYPES = {
  array: 'array',
  image: 'image'
};

export const COMPONENT_CATEGORIES = {
  'M-basic': {
    name: 'Básico',
    icon: 'la-cubes'
  },
  'M-table': {
    name: 'Tabla',
    icon: 'la-th'
  },
  'M-chart': {
    name: 'Chart',
    icon: 'la-bar-chart'
  },
  'M-slider': {
    name: 'Slider',
    icon: 'la-sliders'
  },
  'M-banner': {
    name: 'Banner',
    icon: 'la-image'
  },
  'M-distributor': {
    name: 'Distribuidor',
    icon: 'la-list-ul'
  },
  'M-informative': {
    name: 'Informativo',
    icon: 'la-file-text-o'
  }
};

export const EDITOR_HEADER_BUTTONS: { primary: Array<ITrayItemButton>, secundary: Array<ITrayItemButton> } = {
  primary: [
    {
      description: 'Guardar',
      icon: 'la-save',
      actionType: ACTION_TYPES.save
    }
  ],
  secundary: [
    {
      description: 'Regresar',
      icon: 'la-arrow-left',
      actionType: ACTION_TYPES.back
    }
  ]
};
